/* eslint-disable jsx-a11y/media-has-caption */
import Layout from 'components/Layout';
import Meta from 'components/Meta';
import TestimonialsSlider from 'components/TestimonialsSlider/TestimonialsSlider';
import { Link } from 'gatsby';
import React, { useEffect } from 'react';
import NewFaq from '../../components/NewFaq/NewFaq';
import TrustedBy from '../../components/TrustedBy';
import useMediaQuery from '../../hooks/useMediaQuery';

import 'styles/product-companies.scss';

import { faqDatabugTracking } from '../../utils/contentProvider/faqDataCommon';

const agencyFeatures = [
  {
    id: 0,
    heading: 'Report issues directly on your product',
    description: (
      <>
        Identify and report contextual bugs in the form of comments on your web
        projects. These comments will get added as tickets to your ruttl
        dashboard. No more sending tens of screenshots to get a single point
        across.
      </>
    ),
    buttonText: 'Start Bug Reporting',
    img: '/assets/img/report-bug.png',
  },
  {
    id: 1,
    heading: 'Issue tracking made easy with workflow',
    description: (
      <>
        Use ruttl as your issue tracking system to effortlessly capture, assign,
        prioritize, and resolve bugs in a collaborative manner. You can also
        integrate with popular tools such as Jira, Trello, Asana, and more,
        streamlining your bug management process.
      </>
    ),
    buttonText: 'Track Issues With Workflow',
    img: '/assets/img/track-progress.png',
  },
  {
    id: 2,
    heading: 'Collaborate with your team efficiently',
    description: (
      <>
        Keep every team member in the loop with automatic notifications
        regarding the identified bugs. you can then assign tasks, set deadlines
        and prioritize them as per the need. Jira with fewer steps.
      </>
    ),
    buttonText: 'Keep Your Team Notified',
    img: '/assets/img/collab-with-team.png',
  },
  {
    id: 3,
    heading: 'Integrate with your favourite project management tools',
    description: (
      <>
        Stay updated on new tickets by receiving notifications via your
        preferred tools, such as Slack, Trello, Asana, ClickUp, Jira, or Zapier.
      </>
    ),
    buttonText: 'Check Out The Integrations',
    img: '/assets/img/integration-tools.png',
  },
  {
    id: 4,
    heading: 'Use bug tracking on mobile apps',
    description: (
      <>
        Activate the widget and report bugs directly on your mobile app in the
        form of comments. Create tickets, send them on desktop workflow and
        start tracking bugs.
      </>
    ),
    buttonText: 'Try Mobile App Feedback',
    img: '/assets/img/report-bugs-app.png',
  },

  // {
  //   id: 5,
  //   heading: 'Handle multiple projects easily',
  //   description: (
  //     <>
  //       See all the changes happening in the Activities panel and never lose
  //       track of ongoing activities when you are working on multiple projects.
  //     </>
  //   ),
  //   buttonText: 'Check the Activities Panel',
  //   img: '/assets/img/multiple-projects.png',
  // },
  // {
  //   id: 6,
  //   heading: 'Faster approvals and client satisfaction',
  //   description: (
  //     <>
  //       By better collaboration, visual feedback, tagging people in comments,
  //       you can be sure of getting faster approvals and launching client
  //       websites faster leading to increased customer satisfaction.
  //     </>
  //   ),
  //   buttonText: 'Get Approvals Faster',
  //   img: '/assets/img/guest-commenting.mp4',
  //   isVideo: true,
  // },
];

const ProductCompanies = () => {
  const isLaptop = useMediaQuery('(max-width: 1800px)');
  useEffect(() => {
    const scrollContents = document.querySelectorAll('.single-sticky-content');
    const scrollImages = document.querySelectorAll('.scroll-img ');

    // let margin;

    // if (window.innerWidth < 1200 && window.innerWidth > 991) {
    //   margin = '-200px';
    // } else if (window.innerWidth < 990) {
    //   margin = '-350px';
    // } else {
    //   margin = '-350px';
    // }

    function handleIntersection(entries) {
      entries.map((entry) => {
        if (entry.isIntersecting) {
          [scrollContents, scrollImages].forEach((e) =>
            e.forEach((el) => el.classList.remove('active')),
          );
          [(scrollContents, scrollImages)].forEach((scrollArr) =>
            scrollArr.forEach((scrollItem) =>
              scrollItem.dataset?.id === entry.target.dataset?.id
                ? scrollItem.classList.add('active')
                : '',
            ),
          );
        }
        return entry;
      });
    }

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.2,
      rootMargin: isLaptop ? '-200px' : '-500px',
    });

    scrollContents.forEach((scrollcontent) => observer.observe(scrollcontent));
  }, []);
  return (
    <Layout hideTopBar>
      {() => (
        <>
          <Meta
            title="Best Website Feedback Tool for Designers and Developers | ruttl"
            description="Best bug tracking and reporting tool for product companies - comment on live websites, web apps and mobile apps. Track and review bugs effortlessly."
            url="https://ruttl.com/usecases/for-product-companies/"
          />
          <main>
            <section className="">
              <div className="container producthero">
                <div className="desc">
                  <h1>The easiest bug tracking tool for product companies</h1>
                  <p style={{ marginBottom: '30px' }}>
                    Track issues on web products and mobile apps, organise with
                    Kanban workflow and keep your apps bug-free!
                  </p>
                  <a
                    href="https://web.ruttl.com"
                    type="button"
                    className="button">
                    Try it now for free
                  </a>
                </div>
                <img
                  src="/assets/img/product-companies-hero.png"
                  alt="Product-companies"
                  title="Product-companies"
                />
              </div>
            </section>
            <section className="section-pad">
              <div className="container">
                <div className="flex-row">
                  <div className="col col-6">
                    <div className="sticky-images hidden-mob">
                      {agencyFeatures.map(({ id, img, isVideo }) => (
                        <div
                          data-id={id}
                          className={`scroll-img img${id} ${
                            id === 0 ? 'active' : ''
                          }`}
                          key={id}>
                          {isVideo ? (
                            <video
                              src={img}
                              autoPlay
                              loop
                              muted
                              playsInline></video>
                          ) : (
                            <img
                              src={img}
                              alt="ruttl for product comanpies features"
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col col-md-6 col-5 offset-1">
                    <div className="sticky-content-area">
                      {agencyFeatures.map(
                        ({
                          id,
                          heading,
                          description,
                          buttonText,
                          img,
                          isVideo,
                        }) => (
                          <div
                            key={id}
                            className="single-sticky-content"
                            data-id={id}>
                            <div className="visible-mob">
                              {isVideo ? (
                                <video
                                  src={img}
                                  autoPlay
                                  loop
                                  muted
                                  playsInline></video>
                              ) : (
                                <img src={img} alt={heading} />
                              )}
                            </div>
                            <h2>{heading}</h2>
                            <p>{description}</p>
                            <a
                              href={
                                id === 4
                                  ? '/mobile-app-feedback'
                                  : 'https://web.ruttl.com'
                              }
                              type="button"
                              className="button">
                              {buttonText}
                            </a>
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="section-pad">
              <div className="container">
                <div className="flex-row">
                  <div className="col-md col-8 offset-2">
                    <div className="flex atlassian-feedback">
                      <img
                        src="/assets/img/atlassian-logo-round.png"
                        alt="Atlassian Logo"
                      />

                      <div className="atlassian-feedback-text">
                        <p>
                          “We loved using ruttl. The entire process right from
                          sign-up to commenting & managing feedback is so
                          smooth.
                        </p>

                        <span>
                          <span className="bold">-Josh Shepherd, </span>
                          Event Tech Strategy at Atlassian
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="container center advanced">
              <h3>Advanced Bug tracking tool for QA teams</h3>
              <p>
                Streamline your QA workflow — Set yourself free from the endless
                emails within your team, enable seamless collaboration and
                efficient product management.
              </p>
              <img
                loading="lazy"
                src="/assets/img/product-companies-bug-tracking.png"
                alt="Bug-tracking"
                title="Bug-tracking"
              />
              <Link to="/bug-tracking-tool" type="button" className="button">
                Learn More
              </Link>
              <a
                className="play-now-btn"
                href="https://smashbugs.ruttl.com/"
                target="_blank"
                rel="noopener noreferrer">
                Play Now
              </a>
            </div>
            <div className="container shipProducts">
              <h3>Ship your products faster and bug free</h3>
            </div>
            <div className="center container" style={{ marginBottom: 40 }}>
              <div className="flex-2 flex flex-wrap align-items-start">
                <div className="wrapper">
                  <div className="card-single">
                    <div className="card-block">
                      <div className="card-icon">
                        <img
                          loading="lazy"
                          src="/assets/img/pot.svg"
                          alt="flower pot icon"
                          title="flower pot icon"
                        />
                      </div>
                      <div className="card-desc">
                        <h3>Faster Project Delivery</h3>
                        <p>
                          Go live with your web projects within days or weeks as
                          opposed to months!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wrapper">
                  <div className="card-single">
                    <div className="card-block">
                      <div className="card-icon" style={{ marginBottom: 8 }}>
                        <img
                          loading="lazy"
                          src="/assets/img/open-webpage.png"
                          alt="security icon"
                          title="security icon"
                        />
                      </div>
                      <div className="card-desc">
                        <h3>Smoother Collaboration</h3>
                        <p>
                          Share the project link & ask your clients to comment
                          directly - no sign-up needed!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wrapper">
                  <div className="card-single">
                    <div className="card-block">
                      <div className="card-icon">
                        <img
                          loading="lazy"
                          src="/assets/img/pot.svg"
                          alt="flower pot icon"
                          title="flower pot icon"
                        />
                      </div>
                      <div className="card-desc">
                        <h3>Effective Feedback</h3>
                        <p>
                          Save 50% time when compared to emails with screenshots
                          and documents.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <button
                type="button"
                style={{ marginTop: '30px' }}
                className="button">
                See how it works
              </button> */}
            </div>
            <TestimonialsSlider heading="Agencies love using ruttl" />
            <TrustedBy />
            <div className="container center">
              <div className="get-started">
                <h3>
                  Get started and save both time and money on your next project
                </h3>
                <a
                  href="https://web.ruttl.com"
                  type="button"
                  className="button">
                  Try it now for free
                </a>
              </div>
            </div>
            <NewFaq data={faqDatabugTracking} />
          </main>
        </>
      )}
    </Layout>
  );
};
export default ProductCompanies;
